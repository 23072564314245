<template>
  <validation-observer v-slot="{ invalid }">
    <div
      class="tile is-ancestor"
      style="padding-left: 20px; padding-right: 20px; padding-top: 20px"
    >
      <div class="tile is-3">
        <b-loading
          :active.sync="isLoading"
          :is-full-page="isFullPage"
        ></b-loading>
        <div class="tile box is-child">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Extract from environment"
          >
            <b-field label="Retrieve from environment">
              <b-select
                v-model="extractEnvironment"
                placeholder="Extract from..."
                icon="server"
                expanded
              >
                <option
                  v-for="option in environmentList"
                  :id="option.name"
                  :key="option.name"
                  :value="option.name"
                >
                  {{ option.friendlyName }}
                </option>
              </b-select>
            </b-field>
            <span class="help is-danger">{{ errors[0] }}</span>
          </validation-provider>
          <b-field v-if="extractEnvironment" label="Region (Optional)">
            <b-input v-model="region" name="Region" icon="globe"></b-input>
          </b-field>
          <b-field v-if="extractEnvironment" label="Travel From Key (Optional)">
            <b-input v-model="fkey" name="fkey" icon="arrow-right"></b-input>
          </b-field>
          <b-field v-if="extractEnvironment" label="Travel To Key (Optional)">
            <b-input v-model="tkey" name="tkey" icon="map-marker"></b-input>
          </b-field>
          <b-field v-if="extractEnvironment !== 'file'">
            <b-button
              :disabled="invalid"
              type="is-primary"
              icon-left="trash"
              @click="retrieveData()"
            >
              Retrieve Data
            </b-button>
          </b-field>
          <b-field label="No. of stats per page">
            <b-select v-model="rowsPerPage" placeholder="Stats per page...">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="tile is-parent">
        <div class="tile box is-child tile-table scrolling-wrapper">
          <b-table
            :data="activityData"
            :paginated="true"
            :per-page="rowsPerPage"
            style="overflow-x: auto"
          >
            <b-table-column
              v-slot="props"
              field="avg"
              label="Average"
              numeric
              sortable
              >{{ props.row.avg }}</b-table-column
            >
            <b-table-column
              v-slot="props"
              field="count"
              label="Count"
              numeric
              sortable
              >{{ props.row.count }}</b-table-column
            >
            <b-table-column
              v-slot="props"
              field="dev"
              label="Deviation"
              numeric
              sortable
              >{{ props.row.dev }}</b-table-column
            >
            <b-table-column
              v-slot="props"
              field="fkey"
              label="Travel From Key"
              numeric
              sortable
              >{{ props.row.fkey }}</b-table-column
            >
            <b-table-column
              v-slot="props"
              field="override"
              label="Override"
              numeric
              sortable
              >{{ props.row.override }}</b-table-column
            >
            <b-table-column
              v-slot="props"
              field="region"
              label="Region"
              sortable
              >{{ props.row.region }}</b-table-column
            >
            <b-table-column
              v-slot="props"
              field="tkey"
              label="Travel To Key"
              numeric
              sortable
              >{{ props.row.tkey }}</b-table-column
            >
          </b-table>
          <b-button
            v-if="activityData.length > 0"
            :disabled="activityData.length === 0"
            type="is-danger"
            icon-left="trash"
            @click="discardResults"
          >
            Discard Results
          </b-button>
          <br />
          <b-button
            :disabled="activityData.length === 0"
            type="is-info"
            icon-left="download"
            @click="exportJson"
          >
            Export to JSON
          </b-button>
          <b-button
            :disabled="activityData.length === 0"
            type="is-info"
            icon-left="download"
            @click="exportCsv"
          >
            Export to CSV
          </b-button>
          <p v-if="authError">
            {{ authError }}. Please try logging out and back in
          </p>
          <p v-if="error">{{ error }}</p>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import axios from 'axios';
import saveFile from 'save-as-file';
import Papa from 'papaparse';

import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate';

setInteractionMode('aggressive');

export default defineComponent({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      activityData: [],
      retrieveIsInFlight: false,
      authError: null,
      error: null,
      isLoading: false,
      isFullPage: true,
      resourceParent: this.$configData.activityMigrator.topLevelResource,
      extractEnvironment: null,
      rowsPerPage: 20,
      fileError: null,
      environments: [],
      fkey: null,
      tkey: null,
      region: null,
    };
  },
  methods: {
    recordFileError(err) {
      this.fileError = err.message;
    },
    discardResults() {
      while (this.activityData.length > 0) {
        this.activityData.pop();
      }
    },
    async retrieveData() {
      this.error = null;
      if (!this.retrieveIsInFlight) {
        this.retrieveIsInFlight = true;
        let params = new URLSearchParams();
        params.append('env', this.extractEnvironment);
        params.append('customer', this.$configData.customer.name);
        if (this.tkey) {
          params.append('tkey', this.tkey);
        }
        if (this.fkey) {
          params.append('fkey', this.fkey);
        }
        if (this.region) {
          params.append('region', this.region);
        }

        this.isLoading = true;
        axios
          .get(this.$configData.statsViewer.activityURL, {
            timeout: 60000,
            params: params,
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((result) => {
            this.activityData = result.data;
          })
          .catch((err) => {
            console.error('err: ', err);
            if (this.isErrorUnauthorised(err)) {
              this.$store.dispatch('storeAuth/logout');
            }
            this.error = `Failed to retrieve activities: ${err.response.data.error}`;
          })
          .finally(() => {
            this.retrieveIsInFlight = false;
            this.isLoading = false;
          });
      }
    },
    getFileName(extension) {
      let dateFrom;
      let dateTo;
      let minDate = null;
      let maxDate = null;
      //eslint-disable-next-line no-unused-vars
      for (let row of this.activityData) {
        let rowDate = this.$moment(row.date, 'YYYYMMDD');
        if (!minDate && !maxDate) {
          minDate = rowDate;
          maxDate = rowDate;
        }
        if (minDate.isAfter(rowDate)) {
          minDate = rowDate;
        }
        if (maxDate.isBefore(rowDate)) {
          maxDate = rowDate;
        }
        dateFrom = minDate.format('YYMMDD');
        dateTo = maxDate.format('YYMMDD');
      }
      return `activities_${this.resourceParent}_${dateFrom}_${dateTo}.${extension}`;
    },
    exportCsv() {
      let csv = Papa.unparse(this.activityData);
      let blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
      saveFile(blob, this.getFileName('csv'));
    },
    exportJson() {
      let json = JSON.stringify(this.activityData);
      let blob = new Blob([json], { type: 'text/plain;charset=utf-8' });
      saveFile(blob, this.getFileName('json'));
    },
  },
});
</script>

<style scoped>
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.tile-table {
  flex: 0 0 90%; /* don't grow, don't shrink, stay at 220px width */
}
</style>
